<template>
    <div class="modal-profile-item-selector items-start">
        <div class="back-button-wrapper" :class="{ 'sub-profile': options.from === 'subProfile' }">
            <i class="back-button material-icons" @click="onClickClose">{{ options.icon || 'close' }} </i>
            <div
                v-if="options.from === 'subProfile'"
                @click="onClickSkipButton"
                class="pass-button flex-wrap m-r-16"
                v-html="'건너뛰기'"
            />
        </div>
        <ProgressBar
            v-if="$route.name !== 'MyPage'"
            :from="options.from === 'subProfile' ? 'subProfile' : ''"
            :step="$case.toSnake(options.model)"
        />
        <div class="top">
            <div class="title" v-html="title" />
            <div v-if="showDesc" class="desc" v-html="desc" />
        </div>
        <div v-if="showSearch && options.model !== 'region'" class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <input
                ref="searchValue"
                v-model="searchValue"
                @keydown="onKeydown"
                :placeholder="$translate(`PLACEHOLDER_SEARCH_${options.model.toUpperCase()}`)"
            />
            <i v-if="searchValue" @click="searchValue = ''" class="material-icons">cancel</i>
        </div>
        <div class="no-search-result m-l-20 flex-wrap" v-if="noResult">
            <i class="material-icons-outlined c-danger m-r-4">info</i>
            <span v-html="$translate('NO_SEARCH_RESULT')" />
        </div>
        <ul class="result flex-fill" :class="{ 'grid-col': displayGrid }">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div v-html="$translate(getVal(item))" />
                <i class="material-icons i-right">chevron_right</i>
            </li>
        </ul>
        <BottomButton v-if="this.options.model === 'pet' && isChanged" :label="'저장'" @click="onSave" />
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'
import ProgressBar from '@/routes/signup/components/ProgressBar'

export default {
    name: 'ModalProfileItemSelector',
    props: ['options'],
    data: () => ({
        profileItems: [],
        isChanged: false,
        showSearch: true,
        searchValue: '',
    }),
    components: {
        ProgressBar,
    },
    watch: {
        searchValue(newVal) {
            if (!newVal) this.search()
        },
    },
    computed: {
        userInputAllowed() {
            return ['job', 'jobType', 'company', 'university', 'major'].indexOf(this.options.model) !== -1
        },
        noResult() {
            return this.showSearch && this.searchValue && !(this.profileItems[0] || {}).id
        },
        endpoint() {
            return this.$case.pluralize(this.options.model)
        },
        params() {
            let params = { name: this.searchValue }
            if (this.options.model === 'company') params.jobCategoryId = this.options.profile.job_category.id
            if (this.options.model === 'university') params.schoolTypeId = this.options.profile.school_type.id
            if (this.options.model === 'station') params.region = this.options.profile.state.name
            if (this.options.model === 'region') {
                params = { name: this.options.profile.state.name, district: this.searchValue }
            }
            if (this.options.model === 'exercise') params.exercise = this.options.profile.exercise
            if (this.options.model === 'hometown') params.hometown = this.options.profile.state.name
            if (this.options.model === 'pet') params.pet = this.options.profile.pet
            if (this.options.model === 'car_type') params.carType = this.options.profile.car_type
            if (this.options.model === 'house_payment') params.housePayment = this.options.profile.house_payment
            if (this.options.model === 'house_style') params.houseStyle = this.options.profile.house_style
            return params
        },
        title() {
            if (this.options.model === 'university' || this.options.model === 'company') {
                return this.$translate(this.$case.toConst(`${this.options.model}_input_title`))
            }

            if (this.options.model === 'smoking') {
                return this.$translate(this.$case.toConst(this.options.model)) + ' 여부'
            }

            if (
                ['exercise', 'hometown', 'car_type', 'house_payment', 'house_style', 'pet'].indexOf(
                    this.options.model
                ) > -1
            ) {
                return this.$translate(this.$case.toConst(`${this.options.model}_title`))
            }

            return this.$translate(this.$case.toConst(this.options.model))
        },
        displayGrid() {
            return ['kind', 'mbti', 'pet', 'shape', 'religion', 'car'].indexOf(this.options.model) > -1
        },
        showDesc() {
            return ['kind', 'mbti', 'car'].indexOf(this.options.model) > -1
        },
        desc() {
            if (!this.showDesc) return

            if (this.options.model === 'kind') {
                return '<span class="f-bold c-primary">2가지</span>를 선택해주세요'
            }

            if (this.options.model === 'mbti') {
                return '<span class="f-bold c-primary">성격유형(MBTI)</span>를 선택해주세요'
            }

            if (this.options.model === 'car') {
                return '<span class="f-bold c-primary">미보유</span>를 선택한 경우 프로필에 노출되지 않습니다'
            }

            return ''
        },
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            return JSON.parse(signupData)
        },
    },
    mounted() {
        this.init()
        this.setSelected()
    },
    methods: {
        sendHackleEvent(model) {
            const eventList = ['schoolType', 'jobCategory', 'income', 'smoking']
            if (eventList.includes(model)) {
                this.$hackleClient.onReady(() => {
                    const hackleUser = this.$store.getters.hackleUser
                    this.$hackleClient.track(`Enter_${this.$case.toCapital(model)}Page`, hackleUser)
                })
            }
        },
        setSelected() {
            if (this.options.from === 'subProfile') return

            const model = this.$case.toSnake(this.options.model)
            if (
                [
                    'shape',
                    'kind',
                    'religion',
                    'income',
                    'asset',
                    'exercise',
                    'pet',
                    'car_type',
                    'house_payment',
                    'house_style',
                ].indexOf(this.options.model) !== -1
            ) {
                this.profileItems
                    .filter(o => this.signupData.profile_option_ids.includes(o.id))
                    .forEach(o => this.$set(o, '$$selected', true))
            } else {
                if (this.signupData[model] && this.profileItems) {
                    const selected = this.profileItems.find(o => o.id === this.signupData[model].id)
                    if (selected) this.$set(selected, '$$selected', true)
                }
            }
        },
        init() {
            this.sendHackleEvent(this.options.model)
            this.showSearch = ['region', 'station'].indexOf(this.options.model) !== -1 || this.userInputAllowed

            if (this.options.model === 'smoking') {
                this.profileItems = [
                    { id: 0, name: 'NON_SMOKING' },
                    { id: 1, name: 'SMOKING' },
                ]
                return
            }

            if (this.options.model === 'gender') {
                this.profileItems = [
                    { id: 1, name: 'FEMALE' },
                    { id: 0, name: 'MALE' },
                ]
                return
            }

            if (this.options.model === 'marry') {
                this.profileItems = [
                    { id: 0, name: '아니요, 초혼입니다.', next: false },
                    { id: 1, name: '네, 재혼입니다.', next: true },
                ]
                return
            }

            if (this.options.model === 'haveChild') {
                this.profileItems = [
                    { id: 1, name: '있음' },
                    { id: 0, name: '없음' },
                ]
                return
            }

            if (this.options.model === 'income') {
                this.profileItems = [...this.$store.getters.income]
                return
            }

            if (this.options.model === 'asset') {
                this.profileItems = [...this.$store.getters.asset]
                return
            }

            if (this.options.model === 'exercise') {
                this.profileItems = [...this.$store.getters.exercise]
                return
            }

            if (this.options.model === 'pet') {
                this.profileItems = [...this.$store.getters.pet]

                if (this.options.from !== 'subProfile') {
                    this.profileItems.map(pet => {
                        if (pet.name === '키우지않아요') {
                            this.$set(pet, '$$selected', false)
                        }
                        this.params.pet.includes(pet.name) && this.$set(pet, '$$selected', true)
                    })
                } else {
                    this.profileItems.map(pet => {
                        // if (pet.name === '키우지않아요') {
                        this.$set(pet, '$$selected', false)
                        // }
                    })
                }
                return
            }

            if (this.options.model === 'car_type') {
                this.profileItems = [...this.$store.getters.carType]
                return
            }

            if (this.options.model === 'house_payment') {
                this.profileItems = [...this.$store.getters.housePayment]
                return
            }

            if (this.options.model === 'house_style') {
                this.profileItems = [...this.$store.getters.houseStyle]
                return
            }

            if (this.options.model === 'car') {
                this.profileItems = [{ name: '보유' }, { name: '미보유' }]
                return
            }

            if (!this.showSearch) {
                this.profileItems = this.$store.getters[this.$case.pluralize(this.options.model)]
                // 학력: 대학교 재학 이상일 때 학교구분: 고등학교 졸업 삭제
                if (this.options.model === 'schoolType' && this.options.profile.school.id !== 5) {
                    const temp = this.profileItems.filter(item => {
                        if (item.id !== 14) return item
                    })
                    this.profileItems = temp
                }

                // 직장구분: 대학생/대학원생 삭제
                if (this.options.model === 'jobCategory') {
                    const temp = this.profileItems.filter(item => {
                        if (item.id !== 1) return item
                    })
                    this.profileItems = temp
                }

                if (this.options.model === 'shape' || this.options.model === 'kind') {
                    const gender = this.options.profile.gender || this.$store.getters.me.gender

                    if (typeof gender === 'number') {
                        this.profileItems = this.profileItems.filter(item => item.gender === gender)
                    } else {
                        this.profileItems = this.profileItems.filter(
                            item => item.gender === (gender.name === 'MALE' ? 0 : 1)
                        )
                    }
                }
                return
            }

            this.search()
        },
        search: debounce(async function () {
            try {
                this.profileItems = await profileService[this.endpoint](this.params)

                this.sortRegionAndStation() // 지역, 지하철역 오름차순 정렬

                if (this.profileItems.length === 0 && this.userInputAllowed) {
                    this.profileItems = [{ name: this.searchValue }]
                }

                // 직업 에서도 대학생 삭제
                if (this.options.model === 'job') {
                    const temp = this.profileItems.filter(item => {
                        if (item.id !== 1) return item
                    })
                    this.profileItems = temp
                }
                this.setSelected()
            } catch (e) {
                this.$toast.error(e.data)
            }
        }, 200),
        onKeydown() {
            setTimeout(() => {
                this.searchValue = this.$refs.searchValue.value
                this.search(this.searchValue)
            })
        },
        getVal(o) {
            if (this.userInputAllowed && this.noResult) {
                return `'${this.searchValue}'로 입력하기`
            }

            return this.options.model === 'region' ? o.district : o.name
        },
        onClickItem(item) {
            if (this.options.model === 'pet') {
                if (item.name === '키우지않아요') {
                    this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                } else {
                    this.$set(
                        this.profileItems.find(o => o.name === '키우지않아요'),
                        '$$selected',
                        false
                    )
                }
            }

            if (this.options.model === 'kind') {
                const selected = this.profileItems.filter(o => o.$$selected).map(o => o.id)
                if (selected.includes(item.id)) {
                    this.$set(item, '$$selected', false)
                } else if (selected.length < 2) {
                    this.$set(item, '$$selected', true)
                } else {
                    this.$toast.error('2개까지 선택할 수 있습니다.')
                    return
                }
            } else {
                if (this.options.model === 'pet') {
                    this.$set(item, '$$selected', !item.$$selected)
                    if (this.profileItems.filter(item => item.$$selected).length === 0) {
                        this.$set(
                            this.profileItems.find(o => o.name === '키우지않아요'),
                            '$$selected',
                            true
                        )
                    }

                    const selectedName = this.profileItems
                        .filter(o => o.$$selected)
                        .map(o => o.name)
                        .join(', ')

                    this.isChanged = this.params.pet !== selectedName
                } else {
                    this.$set(item, '$$selected', !item.$$selected)
                }
            }

            setTimeout(() => {
                if (this.options.model === 'kind') {
                    const selected = this.profileItems.filter(o => o.$$selected)
                    if (selected.length < 2) return

                    this.$emit(
                        'close',
                        this.profileItems.filter(o => o.$$selected)
                    )
                } else if (this.options.model !== 'pet') {
                    this.$emit('close', item)
                }

                if (this.options.model !== 'pet') {
                    this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                }
            }, 200)
        },
        sortRegionAndStation() {
            if (this.options.model === 'region') {
                this.profileItems = this.profileItems.sort(function (a, b) {
                    return a.district < b.district ? -1 : a.district > b.district ? 1 : 0
                })
            }

            if (this.options.model === 'station') {
                this.profileItems = this.profileItems.sort(function (a, b) {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                })
            }
        },
        onSave() {
            this.$emit(
                'close',
                this.profileItems.filter(o => o.$$selected)
            )

            if (this.options.model === 'pet') {
                this.profileItems.forEach(o => this.$set(o, '$$selected', false))
            }
        },
        onClickClose() {
            if (this.options.from === 'subProfile' && this.options.model === 'hometown') {
                this.$modal
                    .basic({
                        body: '작성을 그만 두시겠습니까?',
                        buttons: [
                            {
                                label: '계속 작성하기',
                                class: 'btn-primary',
                            },
                            {
                                label: '그만두기',
                                class: 'btn-default',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$emit('close')
                        }
                    })
            } else {
                this.$emit('close')
                if (this.options.model === 'pet' && this.options.from !== 'subProfile') {
                    this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                }
            }
        },
        onClickSkipButton() {
            if (this.options.model === 'pet' && this.options.from === 'subProfile') {
                setTimeout(() => {
                    this.$emit('close', [this.profileItems[0]])
                }, 200)
            } else {
                setTimeout(() => {
                    this.$emit('close', this.profileItems[0])
                }, 200)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-profile-item-selector {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.2px;
    color: $grey-08;

    @include modal-fullscreen;
    @include f-regular;

    .back-button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        &.sub-profile {
            justify-content: space-between;
        }
        justify-content: flex-start;
        padding: 14px $header-left-padding 18px;
    }

    .back-button {
        font-size: 24px;
        color: $grey-08;
        margin: 0;
    }

    .pass-button {
        font-size: 16px;
        color: $grey-08;
        // position: absolute;
        right: 0;
    }

    .top {
        padding: 0 20px;
        margin-bottom: 40px;

        .title {
            font-size: 24px;
            letter-spacing: -0.4px;
            color: black;

            @include f-medium;
        }

        .desc {
            margin-top: 12px;
            font-size: 16px;
            color: $grey-09;
        }
    }

    .result {
        width: 100%;
        overflow-y: auto;
    }

    .input-wrapper {
        display: flex;
        flex-direction: row;
        width: calc(100% - 40px);
        height: 52px;
        border-radius: 8px;
        border: 1px solid $grey-02;
        padding: 12px 16px;
        margin: 0 20px 12px;

        .material-icons {
            font-size: $icon-size;
            color: $grey-05;
            margin: 0;
        }

        .i-search {
            margin-right: 16px;
            color: $grey-08;
        }

        input {
            font-size: 15px;
            margin: 0;
            padding: 0;
            color: black;
        }

        input::placeholder {
            color: $grey-05;
        }
    }

    .no-search-result {
        color: $brand-danger;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .material-icons-outlined {
            font-size: 14px;
        }
    }

    .item {
        padding: 14px 20px;
        font-size: 16px;
        line-height: 1.38;

        > div {
            max-width: 280px;
            @include lines-1;
        }

        &.selected {
            color: $purple-primary;
            @include f-bold;

            .i-right {
                color: $purple-primary;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $grey-01;
        }
    }

    .i-right {
        font-size: 24px;
        margin: 0;
        color: $grey-04;
    }

    .grid-col {
        margin: 0 20px;
        grid-gap: 12px;
        grid-auto-rows: 48px;
        border-top: none;
        width: initial;

        .item {
            border-radius: 8px;
            border: 1px solid $grey-02;
            width: calc((100vw - 12px - 40px) / 2);
            @include center;

            &.selected {
                border: 1px solid $purple-primary;
            }

            .i-right {
                display: none;
            }
        }
    }
}
</style>
